import React from "react"

import SEO from "../components/common/seo"
import Breadcrumbs from "../components/common/breadcrumbs";
import LayoutSecondary from "../components/common/layout-secondary";
import {Link} from "@reach/router";
import {graphql} from "gatsby";
import ReactMarkdown from "react-markdown";
import {customElements} from "../utils/global-functions";
import {OutboundLink} from "gatsby-plugin-google-gtag";
import rehypeRaw from "rehype-raw";

const PrivacityPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const page = data.allStrapiLegalCondicionesDeUso.edges[0].node;
    const breadcrumbs = [
        {
            text: 'Legal',
            active: false,
        },
        {
            text: page.title,
            active: true,
        }
    ];
    const privacyElements = (aClassName = '') => {
        return {
            p: ({node, ...props}) => <p className='text-black-800 mb-2 spacing font-size-sm' {...props} />,
            h2: ({node, ...props}) => <h5 className='font-weight-bold mt-6 spacing text-tecalis' {...props} />,
            h3: ({node, ...props}) => <h3 className='font-weight-bold mt-6 spacing text-tecalis' {...props} />,
            h5: ({node, ...props}) => <h5 className='font-weight-bold mt-6 spacing text-tecalis' {...props} />,
            a: ({node, ...props}) => {
                if (props.href.startsWith('http')) {
                    return <OutboundLink href={props.href} target="_blank" rel="noopener noreferrer" className={aClassName} {...props} />
                }

                return <Link to={props.href} className={aClassName} {...props} />
            },
        }
    }

    return (
        <LayoutSecondary pageContext={pageContext}>
            <SEO lang={lang} title={page.seo.title} description={page.seo.meta_description}/>

            <Breadcrumbs breadcrumbs={breadcrumbs}/>

            {/** LEGAL
             ================================================== */}
            <section className="bg-grey pt-8 pt-md-9">
                <div className="container">

                    {/** Heading */}
                    <h1 className="display-4 font-weight-bold spacing">
                        {page.title}
                        <br/><br/>
                    </h1>

                    <div className="row">
                        <div className="col-12 col-md-12">

                            <ReactMarkdown children={page.short_description} rehypePlugins={[rehypeRaw]} components={customElements('text-black-800 mb-6 spacing font-size-sm')}/>

                            <div className="table-responsive">
                                <table className="table">
                                    <tbody>
                                    {page.items.map((item, i) => {
                                        return <tr key={i}>
                                            <td className="spacing font-size-sm font-weight-bold">{item.title}</td>
                                            <td className="spacing font-size-sm"><ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]}/></td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>
                            </div>

                            <br/>

                            <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} components={privacyElements('text-black-800 mb-6 spacing font-size-sm')}/>

                            <br/><br/>

                        </div>

                    </div>
                </div>
                {/** / .row */}
            </section>

        </LayoutSecondary>
    )
};

export default PrivacityPage

export const privacityPageQuery = graphql
    `query($lang: String)
    {
        allStrapiLegalCondicionesDeUso (
            filter: { locale: { eq: $lang } }
        ) {
            edges {
                node {
                    id,
                    seo {
                        title,
                        meta_description
                    }
                    title
                    short_description
                    items {
                        order
                        title
                        description
                    }
                    description
                }
            }
        }
    }`;
